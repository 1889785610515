import { FindAccountRequest, RegisterRequest, SubmitNewPasswordRequest, CheckAnswersRequest, DeleteUserRequest } from '../models/register.model';
import { UpdateRequest } from '../models/updateProfile.model';
import axios from 'axios';
import { BehaviorSubject, Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';

let apiUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://api.evidencediary.com';

export class ApiService {
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject(localStorage.getItem('evi-token'));

  public onTokenChanged(): Observable<string> {
    return this.tokenSubject.asObservable();
  }

  async register(registerRequest: RegisterRequest): Promise<any> {
    return (await axios.post(apiUrl + '/register', registerRequest)).data;
  }

  async findAccount(findAccountRequest: FindAccountRequest): Promise<any> {
    return (await axios.post(apiUrl + '/find-account', findAccountRequest)).data;
  }

  async submitNewPassword(submitNewPasswordRequest: SubmitNewPasswordRequest): Promise<any> {
    return (await axios.post(apiUrl + '/submit-new-password', submitNewPasswordRequest)).data;
  }

  async getProfile(): Promise<any> {
    return await this.authenticatedRequest(axios.get(apiUrl + '/profile', { headers: { Authorization: 'Bearer ' + this.tokenSubject.value } }));
  }
  async getSecurityQuestions(): Promise<any> {
    return await this.authenticatedRequest(axios.get(apiUrl + '/get-security-questions', { headers: { Authorization: 'Bearer ' + this.tokenSubject.value } }));
  }
  async checkAnswers(checkAnswersRequest: CheckAnswersRequest): Promise<any> {
    return await this.authenticatedRequest(axios.post(apiUrl + '/check-answers', checkAnswersRequest, { headers: { Authorization: 'Bearer ' + this.tokenSubject.value } }));
  }

  async deleteUserRequest(deleteUserRequest: DeleteUserRequest): Promise<any> {
    this.setToken((await this.authenticatedRequest(axios.post(apiUrl + '/delete-user-request', deleteUserRequest, { headers: { Authorization: 'Bearer ' + this.tokenSubject.value } }))).data);
  }

  async reactivateUser(): Promise<any> {
    this.setToken((await this.authenticatedRequest(axios.post(apiUrl + '/reactivate', {}, { headers: { Authorization: 'Bearer ' + this.tokenSubject.value } }))).data);
  }

  async updateProfile(updateRequest: UpdateRequest): Promise<any> {
    return await this.authenticatedRequest(axios.post(apiUrl + '/update-profile', updateRequest, { headers: { Authorization: 'Bearer ' + this.tokenSubject.value } }));
  }

  async login(username: string, password: string): Promise<any> {
    this.setToken((await axios.post<any>(apiUrl + '/auth/login', { username: username, password: password })).data);
  }

  async checkActivationCode(code: string): Promise<boolean> {
    const result = (await axios.post(apiUrl + '/validateCode', { code: code }));
    return !!result.data;
  }

  async logout() {
    this.setToken(null);
  }

  setToken(token?: string) {
    if(token) {
      localStorage.setItem('evi-token', token);
    } else {
      localStorage.removeItem('evi-token');
    }

    this.tokenSubject.next(token);
  }

  getCurrentUser(): any {
    return this.tokenSubject.value ? jwt_decode(this.tokenSubject.value) : null;
  }

  isLoggedIn(): boolean {
    return this.tokenSubject.value != null;
  }

  async getPosts(start: Date, end: Date, sort?: number) {
    let posts = await this.authenticatedRequest(axios.get(apiUrl + '/posts', { params: { start, end, sort }, headers: { Authorization: 'Bearer ' + this.tokenSubject.value } }));

    if(posts && posts.length > 0) {
      posts = posts.map(p => { return { ...p, date: new Date(p.date) }; });
    }

    return posts;
  }

  async createPost(data) {
    return await this.authenticatedRequest(axios.post(apiUrl + '/posts', data, { headers: { Authorization: 'Bearer ' + this.tokenSubject.value } }));
  }

  async sign(type, postId, originalName) {
    return await this.authenticatedRequest(axios.get(apiUrl + '/sign', { params: { type, postId, originalName }, headers: { Authorization: 'Bearer ' + this.tokenSubject.value } }));
  }

  async getSignedMediaUrl(key) {
    return await this.authenticatedRequest(axios.get(apiUrl + '/sign', { params: { type: 'view-media', key }, headers: { Authorization: 'Bearer ' + this.tokenSubject.value } }));
  }

  async uploadFile(signResponse, file) {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    bodyFormData.append('key', signResponse.fields.key);

    Object.keys(signResponse.fields).forEach(field => {
      bodyFormData.append(field, signResponse.fields[field]);
    });

    const uploadResponse = await axios.post(signResponse.url, bodyFormData);

    const headObject = await this.authenticatedRequest(axios.get(apiUrl + '/head-object', {
      params: {
        postId: signResponse.fields['X-Amz-meta-postid'],
        filename: signResponse.fields['X-Amz-meta-filename']
      },
      headers: { Authorization: 'Bearer ' + this.tokenSubject.value }
    }));

    return {
      signResponse,
      uploadResponse,
      headObject
    };
  }

  async generateReport(data) {
    return await this.authenticatedRequest(axios.post(apiUrl + '/generate-report', data, { headers: { Authorization: 'Bearer ' + this.tokenSubject.value } }));
  }

  async authenticatedRequest(req: Promise<any>) {
    try {
      return (await req).data;
    } catch(err) {
      if(err?.response?.status === 401) {
        this.logout();
      } else {
        throw err;
      }
    }
  }
}