import React, { useContext, useState } from 'react';
import { ApiService } from '../services/api.service';

const ApiContext = React.createContext<ApiService>(null);

export const useApi = () => useContext(ApiContext);

export function ApiProvider({ children }) {
  const [service] = useState(new ApiService());
  return <ApiContext.Provider value={service}>{children}</ApiContext.Provider>;
}